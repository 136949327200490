import { Link } from "gatsby"
import React, { Component } from 'react'
import loadable from '@loadable/component'
import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@reach/tabs";

import Loader from "../components/loader";

const Head = loadable(() => import("../components/head"));
const InnerLayout = loadable(() => import('../components/Innerlayout'));
const EngMetaverseHealthcarePage = loadable(() => import("../components/MetaverseHealthcarePage/english.js"));
const ArabicMetaverseHealthcarePage = loadable(() => import("../components/MetaverseHealthcarePage/arabic.js"));
const ChineseMetaverseHealthcarePage = loadable(() => import("../components/MetaverseHealthcarePage/chinese.js"));

export class MetaverseHealthcarePage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            lang: "English",
        }
    };

    async componentDidMount() {
        let region = localStorage.getItem('region');

        if (region == 'ae') this.setState({ lang: "Arabic" });
        // else if (region == 'sa') this.setState({ lang: "Arabic" });
        else if (region == 'cn') this.setState({ lang: "China" });
        else this.setState({ lang: "English" });
    };

    render() {
        let { lang } = this.state;

        return (
            <>
                <InnerLayout header='Main' fallback={<Loader />}>
                    <Head
                        type="Website"
                        fallback={<Loader />}
                        path="/metaverse-healthcare-solutions/"
                        title="Metaverse Healthcare Solutions Provider | Softtik"
                        thumbnail="https://softtik.com/images/nft-collection/about-token.webp"
                        description="Metaverse is stirring up the healthcare ecosystem with its innovative solutions. We're the leading metaverse healthcare solutions company with a talented team. "
                    />
                    <main>
                        <div id="wrapper">
                            <div className={`collection-page metaverse-token-page ${lang == "Arabic" && 'rtl'}`}>
                                {lang == "Arabic"
                                    ? <ArabicMetaverseHealthcarePage />
                                    : lang == "China"
                                        ? <ChineseMetaverseHealthcarePage />
                                        : <EngMetaverseHealthcarePage />
                                }
                            </div>
                        </div>
                    </main>
                </InnerLayout >
            </>
        )
    };
};

export default MetaverseHealthcarePage;